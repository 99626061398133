import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        Accessible Analytics <span>coming soon...</span>
      </header>
    </div>
  );
}

export default App;
